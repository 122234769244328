import React from 'react';
import styles from './ContactForm.module.scss';

type Props = {
};

const ContactForm = () => (
<div className={styles['contactForm']}>
  <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
    <input type="hidden" name="form-name" value="contact" />
    <input type="hidden" name="bot-field" />
    <div className={styles['contactForm__row']}>
      <div className={styles['contactForm__col-25']}>
        <label className={styles['contactForm__label']} htmlFor="name">Name</label>
      </div>
      <div className={styles['contactForm__col-75']}>
        <input type="text" id="name" name="name" required/>
      </div>
    </div>
    <div className={styles['contactForm__row']}>
      <div className={styles['contactForm__col-25']}>
        <label className={styles['contactForm__label']} htmlFor="email">Email</label>
      </div>
      <div className={styles['contactForm__col-75']}>
        <input type="text" id="email" name="email" required/>
      </div>
    </div>
    {/* Dropdown below */}
    {/* <div className={styles['contactForm__row']}>
      <div className={styles['contactForm__col-25']}>
        <label className={styles['contactForm__label']} htmlFor="country">Country</label>
      </div>
      <div className={styles['contactForm__col-75']}>
        <select id="country" name="country">
          <option value="australia">Australia</option>
          <option value="canada">Canada</option>
          <option value="usa">USA</option>
        </select>
      </div>
    </div> */}
    <div className={styles['contactForm__row']}>
      <div className={styles['contactForm__col-25']}>
        <label className={styles['contactForm__label']} htmlFor="message">Message</label>
      </div>
      <div className={styles['contactForm__col-75']}>
        <textarea id="message" name="message" placeholder="What's on your mind..." className={styles['contactForm__textArea']} required></textarea>
      </div>
    </div>
    <div className={styles['contactForm__row']}>
      <input type="submit" value="Submit"/>
    </div>
  </form>
</div>

);
export default ContactForm;