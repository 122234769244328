import React from 'react';
import styles from './ContactPage.module.scss';
import ContactForm from './ContactForm';

type Props = {
  title?: string,
  children: React.Node
};

const ContactPage = ({ title, children }: Props) => (
    <div className={styles['contactPage']}>
      <div className={styles['contactPage__inner']}>
        { title && <h1 className={styles['contactPage__title']}>{title}</h1>}
        <div className={styles['contactPage__body']}>
          {children}
          <ContactForm/>
        </div>
      </div>
    </div>
);
export default ContactPage;